import React, { useState } from "react";


const TestimonialComponent = () => {

    const [currentTestimonial, setCurrentTestimonial] = useState(0);


    const goToPrevious = () => {
        setCurrentTestimonial((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
    };

    const goToNext = () => {
        setCurrentTestimonial((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    };

    const testimonials = [
        {
            name: 'Nehal Rangari',
            designation: 'Director at Crisfood',
            testimonial: 'We are incredibly grateful for the outstanding work done by Crisfood DigiVerse. Their expertise in digital marketing and development has transformed our business. Their teams professionalism, communication, and dedication to our success have been remarkable. They consistently go above and beyond to ensure our campaigns are effective and our website and app remains cutting-edge. Thanks to Crisfood DigiVerse, our online presence has soared, resulting in increased brand recognition and higher customer engagement. We wholeheartedly endorse their services.',
        },
        {
            name: 'Ayyub Peerzade',
            designation: 'Silver Fork',
            testimonial: 'Working with Crisfood DigiVerse has been a game-changer for our business. Their digital marketing strategies and web development expertise have significantly increased our online visibility and generated a substantial boost in website traffic. Their team is knowledgeable, responsive, and goes above and beyond to deliver exceptional results. We highly recommend their services.',
        },
        {
            name: 'Sohel',
            designation: 'Sahil Hotel',
            testimonial: 'We are thrilled with the results Crisfood DigiVerse has achieved for our brand. Their comprehensive digital marketing approach, coupled with their innovative web development solutions, has helped us reach our target audience effectively and drive significant conversions. The teams professionalism, creativity, and attention to detail are unparalleled. Its been a pleasure partnering with them.',
        },
        {
            name: 'Abid Dhole',
            designation: 'Fire Wings waffle',
            testimonial: 'I cant say enough positive things about Crisfood DigiVerse. From the moment we engaged their services, their team demonstrated a deep understanding of our business goals and industry. They crafted a tailored digital marketing strategy that perfectly aligned with our objectives and consistently delivered outstanding results. Their expertise in web development ensured that our website not only looked visually stunning but also provided an exceptional user experience. Working with Crisfood DigiVerse has been a true partnership, and we couldnt be happier with the outcomes.',
        },
        {
            name: 'Mujahid Patel',
            designation: 'Director at Ariss',
            testimonial: 'Choosing Crisfood DigiVerse as our digital marketing and development agency was one of the best decisions we made for our online presence. Their team has an incredible blend of creativity, technical expertise, and strategic thinking. They took the time to understand our brand, industry, and target audience, and then developed a comprehensive digital strategy that yielded remarkable results. Their attention to detail and commitment to delivering exceptional customer service set them apart. We highly recommend Crisfood DigiVerseto any business looking to elevate their digital presence.',
        },
    ];

    const testimonial = testimonials[currentTestimonial];

    return (
        <>
            <div className="testimonial-slider">
                <div className="background"></div>
                <div className="testimonial">
                    <h3 className="testimonial-name">{testimonial.name}</h3>
                    <p className="testimonial-quote">"{testimonial.testimonial}"</p>
                    <p className="testimonial-designation">{testimonial.designation}</p>
                </div>
            </div>
            <div className="slider-controls">
                <div className="tp-contact-btn me-3">
                    <button onClick={goToPrevious} className="tp-btn">Previous</button>
                </div>
                <div className="tp-contact-btn">
                    <button onClick={goToNext} className="tp-btn">Next</button>
                </div>
            </div>
        </>
    )
}
export default TestimonialComponent