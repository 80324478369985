import React from 'react';
import Shape2 from '../../assets/images/portfoilo/shape-2.png'
import Shape3 from '../../assets/images/portfoilo/shape-3.png'
import Conversation from '../../assets/images/portfoilo/conversation.png'
import Headphone from '../../assets/images/portfoilo/headphone.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';


const PortfolioCatAreaCard = () => {

    return (
        <>
            <div className="tp-portfolio-cta-area p-relative">
                <div className="tp-cta-4-shape fadeUp">
                    <LazyLoadImage src={Shape2} alt="" />
                </div>
                <div className="container">
                    <div className="row gx-0">
                        <div className="col-xl-6">
                            <div className="tp-cta-4-wrapper-left">
                                <LazyLoadImage className="shape-1" src={Shape3} alt="" />
                                <div className="tp-cta-4-mail d-flex flex-wrap justify-content-center justify-content-xl-start align-items-center">
                                    <LazyLoadImage src={Conversation} alt="" />
                                    <a href="mailto:info@crisfooddigiverse.com" target='_blank'>Contact Us  <span>info@crisfooddigiverse.com</span></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="tp-cta-4-wrapper-right">
                                <div className="tp-cta-4-headphone d-flex flex-wrap justify-content-center ">
                                    <div className="tp-cta-4-headphone-thumb">
                                        <LazyLoadImage src={Headphone} alt="" />
                                    </div>
                                    <div className="tp-cta-4-content">
                                        <p>PERFECT SOLUTION From  <span>It Advisor</span></p>
                                        <a href="tel:+919158445043">+91 9158445043</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PortfolioCatAreaCard;
