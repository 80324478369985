import React, { useState } from "react";
import BannerImage from '../../components/BannerSlider'
import PartnerCard from "../../components/PartnerCard";
import BrandSLogoComponent from "../../components/BrandSLogoComponent";
import IndustriesOferingComponent from "../../components/IndustriesOferingComponent";
import FooterPage from "../FooterPage";
import TestimonialComponent from "../../components/TestimonialComponent";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Sideline from "../../assets/images/svg/underline.svg";
import WorkTogetherCard from "../../components/WorkTogetherCard";
import OurServicesCard from "../../components/OurServicesCard";
import PortFolioCardComponent from "../../components/PortFolioCardComponent";
import { LazyLoadImage } from "react-lazy-load-image-component";










const HomePage = ({ }) => {

	const [productData, setProductData] = useState([
		{
			title: "IT Industry",
			name: 'In marketing and development, there are milestones to reach the desired outcome. We offer solutions for all industries. If you are a similar business, we would be delighted to provide you with similar services',
			image: require('../../assets/images/home/partner_card/crisfood_digiverse_partner_card_image_1.jpg'),
		},
		{
			title: "Restaurant",
			name: 'To manage orders and table bookings online, we have offered websites Plus applications that are accessible across all devices, allowing customers and owners to receive notifications about orders and table bookings at the same time',
			image: require('../../assets/images/home/partner_card/crisfood_digiverse_partner_card_image_2.jpg'),
		},
		{
			title: "Construction",
			name: 'We have developed a solution for the construction industry by creating a website that allows users to check availability and deal with the property with minimal interference',
			image: require('../../assets/images/home/partner_card/crisfood_digiverse_partner_card_image_3.jpg'),
		},
		{
			title: "Electronics",
			name: 'The Electronics and Electrical Services company has a customised website and a digital marketing strategy designed to increase sales and generate more ROI',
			image: require('../../assets/images/home/partner_card/crisfood_digiverse_partner_card_image_4.jpg'),
		},
	]);

	const [PortFolioData, setPortFolioData] = useState([
		{
			name: 'Food Delivery App',
			secondName: 'Crisfood',
			image: require('../../assets/images/portfoilo/company/crisfood_digiverse_crisfood.jpg'),
			projects: "View Projects",
			link: "https://crisfood.com/",
		},
		{
			name: 'Restaurant Website',
			secondName: 'FireWings',
			image: require('../../assets/images/portfoilo/company/crisfood_digiverse_fire_wings.jpg'),
			projects: "View Projects",
			link: "https://indiafirewings.com/",
		},
		{
			name: 'Automation Website',
			secondName: 'Ariss',
			image: require('../../assets/images/portfoilo/company/crisfood_digiverse_ariss.jpg'),
			projects: "View Projects",
			link: "https://www.ariss.io",
		},
		{
			name: 'Wedding Website',
			secondName: 'Humsafar',
			image: require('../../assets/images/portfoilo/company/crisfood_digiverse_humsafar.jpg'),
			projects: "View Projects",
			link: "https://www.humsafarmarriage.com/",
		},
		{
			name: 'Restaurant Website',
			secondName: 'Sahil Hotel',
			image: require('../../assets/images/portfoilo/company/crisfood_digiverse_sahil.jpg'),
			projects: "View Projects",
			link: "https://sahilhotelbhiwandi.com/",
		},
		{
			name: 'Restaurant Website',
			secondName: 'Silver Fork Madina',
			image: require('../../assets/images/portfoilo/company/crisfood_digiverse_silver.jpg'),
			projects: "View Projects",
			link: "https://silverforkmadina.com/home/index.html",
		},
		{
			name: 'Construction Website',
			secondName: 'Unity Construction',
			image: require('../../assets/images/portfoilo/company/crisfood_digiverse_unity_construction.jpg'),
			projects: "View Projects",
			link: "https://unityconstruction.in/",
		},
	]);



	return (
		<>
			<BannerImage />
			<h2 className="text-center mt-4 digiverse-your-digital-text">Digiverse Your Digital Solution Partner</h2>
			{/* <div ref={scrollDemo}>
			</div> */}
			<PartnerCard productData={productData} />
			{/* <IndustriesOferingComponent /> */}
			<div className="mt-5">
				<div className="container">
					<div className="tp-feature-title-wrapper">
						<h3 className="tp-section-title">Top Clients
							<span className="title-left-shape">
								<LazyLoadImage src={Sideline} alt="" />
							</span>
						</h3>
					</div>
					<p>Whether you are a startup or an established business, we provide web development services to fit your needs. With the help of our experienced developers, we design and build user-friendly and visually appealing websites. Using the latest technologies, we ensure that your website is fast, secure, and scalable.</p>
				</div>
				<BrandSLogoComponent />
			</div>
			<div className="container tp-feature-title-wrapper">
				<h3 className="tp-section-title">Our Projects
					<span className="title-left-shape">
						<LazyLoadImage src={Sideline} alt="" />
					</span>
				</h3>
			</div>
			<PortFolioCardComponent PortFolioData={PortFolioData} />
			{/* <div className="testimonial-text-with-bg">Testimonial</div> */}
			<div className="container tp-feature-title-wrapper">
				<h3 className="tp-section-title">Testimonial
					<span className="title-left-shape">
						<LazyLoadImage src={Sideline} alt="" />
					</span>
				</h3>
			</div>
			<TestimonialComponent />
			<div className="services">
				<div className="container tp-feature-title-wrapper">
					<h3 className="tp-section-title">Our Services
						<span className="title-left-shape">
							<LazyLoadImage src={Sideline} alt="" />
						</span>
					</h3>
				</div>
				<OurServicesCard />
			</div>

			<WorkTogetherCard />
			<FooterPage />
		</>
	)
}
export default HomePage