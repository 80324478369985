import React, { useEffect, useState } from "react";
import DigiverseLogo from "../../assets/images/png/crisfood-digiverse-logo.png"
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCloudSun, faMoon, faSun, faSunPlantWilt } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import { ThemeContext, themes } from "../../utils/ThemeContext/ThemeContext";
import { LazyLoadImage } from "react-lazy-load-image-component";

const HeaderComponent = ({ offset }) => {
	const [darkMode, setDarkMode] = React.useState(true);
	const [isNavVisible, setNavVisibility] = useState(false);
	const location = useLocation();
	const [pathname, setPathname] = useState('')
	useEffect(() => {
		setPathname(location.pathname)
	}, [])

	const toggleNav = () => {
		setNavVisibility(!isNavVisible);
	};

	return (
		<>
			{/*----------------------------- NavBar Section ----------------------*/}
			<div className="container-fluid p-0">
				<header className={pathname == "/" ? offset >= 350 ? "header-with-scroll" : "header-without-scroll" : offset >= 250 ? "header-with-scroll" : "header-without-scroll"}>
					<Link to={'/'} className="digiverse-logo-conatiner">
						<div className="digiverse-logo">
							<LazyLoadImage src={DigiverseLogo} alt="" />
						</div>
						<div className="digiverse-text" href="/">Digiverse</div>
					</Link>
					<div className={`Nav ${isNavVisible ? 'open' : ''}`}>
						<Link to={'/'} onClick={() => { setNavVisibility(!isNavVisible) }} >Home</Link>
						{/* <Link to={'/our-services'} >Our Services</Link> */}
						<Link to={'/portfolio'} onClick={() => { setNavVisibility(!isNavVisible) }} >Portfolio</Link>
						<Link to={'/about'} onClick={() => { setNavVisibility(!isNavVisible) }} >About</Link>
						<Link to={'/contact'} onClick={() => { setNavVisibility(!isNavVisible) }} >Contact</Link>
						<ThemeContext.Consumer>
							{({ changeTheme }) => (
								<Button
									variant={'outline-info'}
									onClick={() => {
										setDarkMode(!darkMode);
										changeTheme(darkMode ? themes.light : themes.dark);
										setNavVisibility(!isNavVisible)
									}}
								>
									{
										!darkMode ?
											<FontAwesomeIcon icon={faCloudSun} />
											:
											<FontAwesomeIcon icon={faMoon} />
									}
									<i className={darkMode ? 'fas fa-sun' : 'fas fa-moon'}></i>
								</Button>
							)}
						</ThemeContext.Consumer>
					</div>
					<button onClick={toggleNav} className="toggle-navigation">
						<FontAwesomeIcon className="faBars" icon={faBars} />
					</button>
				</header>
			</div>
		</>
	)
};

export default HeaderComponent;