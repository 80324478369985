import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HeaderComponent from './components/HeaderComponent/HeaderComponent';
import HomePage from './pages/HomePage';
import PortfolioPage from './pages/PortfolioPage'
import ContactUs from './pages/ContactUsPage';
import AboutUsPage from './pages/AboutUsPage';
// import OurServicesPage from './pages/OurServices';
import ThemeContextWrapper from './utils/ThemeContextWrapper/ThemeContextWrapper';
import NotFoundPage from './pages/NotFoundPage';

function App() {

  const scrollDemo = useRef()
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, [])

  return (
    <>
      {/*----------------------------- Browser Router Section ----------------------*/}
      <BrowserRouter>
        <ThemeContextWrapper>
          <div className="App">
            {/*----------------------------- Header Section ----------------------*/}
            <div className='header'>
              <HeaderComponent offset={offset} />
              {/*----------------------------- Toaster Section ----------------------*/}
              {/* <Toaster position="top-right1"></Toaster> */}
            </div>
            {/*----------------------------- Main Contant ----------------------*/}
            <div className="main-content">
              <Routes>
                <Route path='/' element={<HomePage scrollDemo={scrollDemo} />} />
                {/* <Route path='/our-services' element={<OurServicesPage scrollDemo={scrollDemo} />} /> */}
                <Route path='/portfolio' element={<PortfolioPage />} />
                <Route path='/about' element={<AboutUsPage />} />
                <Route path='/contact' element={<ContactUs />} />
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </div>
          </div>
        </ThemeContextWrapper>
      </BrowserRouter>
    </>
  );
}

export default App;
