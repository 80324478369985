import React from "react";
import CommonBannerImage from "../../components/CommonBannerImage";
import AboutITSolutionsComponent from "../../components/AboutITSolutionsComponent";
import AboutPageCounterAreaComponent from "../../components/AboutPageCounterAreaComponent";
import ServiceITSolutionsComponent from "../../components/ServiceITSolutionsComponent";
import WhyChoosUsComponents from "../../components/WhyChoosUsComponents";
import FooterPage from "../FooterPage";
import OurServicesCard from "../../components/OurServicesCard";



const AboutUsPage = () => {
	return (
		<>
			<CommonBannerImage />
			<AboutITSolutionsComponent />
			{/* <AboutPageCounterAreaComponent /> */}
			<OurServicesCard />
			<ServiceITSolutionsComponent />
			<WhyChoosUsComponents />
			<FooterPage />
		</>
	)
}
export default AboutUsPage