import React from "react";
import About2Image from "../../assets/images/about/img-1.jpg"
import About3Image from "../../assets/images/about/img-2.jpg"
import About4Image from "../../assets/images/about/img-3.jpg"
import About5Image from "../../assets/images/about/shape-1.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import Ringundrline from "../../assets/images/svg/ring-inderline.svg";
import Sideline from "../../assets/images/svg/underline.svg";
import IconRightSvg from "../../assets/images/svg/icon-right.svg";
import { Link } from "react-router-dom";

import About6Image from "../../assets/images/about/img-4.jpg"
import About7Image from "../../assets/images/about/img-5.jpg"
import About8Image from "../../assets/images/about/img-6.jpg"
import { LazyLoadImage } from "react-lazy-load-image-component";





const ServiceITSolutionsComponent = () => {

    return (
        <>

            <section className="tp-support-breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="tp-fun-fact-title-wrapper support-breadcrumb">
                                <h5>
                                    <span className="tp-section-title__pre">
                                        services <span className="title-pre-color">Web Development</span>
                                        <LazyLoadImage src={Ringundrline} alt="" />
                                    </span>
                                </h5>
                                {/* <h3 className="tp-section-title">We’ve been stay <span className="title-color">5 years</span>
                                    <span className="title-left-shape">
                                        <LazyLoadImage src={Sideline} alt="" />
                                    </span>
                                </h3> */}
                                <p className="tp-section-title-text-pera">At Crisfood DigiVerse, we believe that every business is unique, and we approach each project with a customized strategy that aligns with your specific goals and target audience. Our relentless focus on delivering measurable results, coupled with our commitment to transparency and exceptional customer service, sets us apart as a trusted partner for businesses of all sizes.
                                    <br />
                                    Ready to take your digital presence to the next level? Contact Crisfood DigiVerse today, and let's embark on an exciting journey together to elevate your brand and achieve your online ambitions.</p>

                                {/* <h5 className="tp-section-title">Services <span className="title-color">:</span>
                                    <span className="title-left-shape">
                                        <LazyLoadImage src={Sideline} alt="" />
                                    </span>
                                </h5> */}
                                <ul className="mb-4 web-development-text-point">
                                    <li className="mb-2"><span>
                                        <LazyLoadImage src={IconRightSvg} className="me-2" />
                                    </span>
                                        <b>Custom Website Design: </b>
                                        Our talented designers create visually stunning, user-friendly websites tailored to your brand identity and optimized for seamless user experiences
                                    </li>
                                    <li className="mb-2"><span>
                                        <LazyLoadImage src={IconRightSvg} className="me-2" />
                                    </span>
                                        <b>E-Commerce Development: </b>
                                        We help businesses establish and grow their online stores, integrating secure payment gateways, intuitive navigation, and robust inventory management systems.
                                    </li>
                                    <li className="mb-2"><span>
                                        <LazyLoadImage src={IconRightSvg} className="me-2" />
                                    </span>
                                        <b>Mobile App Development: </b>
                                        Capture the ever-growing mobile market with user-friendly and feature-rich mobile applications that enhance customer engagement and drive brand loyalty.
                                    </li>
                                    <li className="mb-2"><span>
                                        <LazyLoadImage src={IconRightSvg} className="me-2" />
                                    </span>
                                        <b>Website Maintenance and Support: </b>
                                        Our team provides ongoing maintenance, security updates, and technical support to ensure your website operates smoothly and remains up-to-date.
                                    </li>
                                </ul>
                                {/* <div className="tp-support-breadcrumb-btn mb-30">
                                    <Link className="tp-btn" to={"/contact"}>How Can We Help</Link>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="tp-about-3-img p-relative fadeRight">
                                <LazyLoadImage src={About2Image} alt="" />
                                <LazyLoadImage className="shape-1" src={About3Image} alt="" />
                                <div className="shape-2 p-relative">
                                    <LazyLoadImage src={About4Image} alt="" />
                                    <div className="tp-video-play">
                                        <a className="popup-video" href="https://www.youtube.com/@CrisfoodDigiverse/featured" target="_blank">
                                            <FontAwesomeIcon icon={faPlay} />
                                        </a>
                                    </div>
                                </div>
                                <LazyLoadImage className="shape-3" src={About5Image} alt="" />
                            </div>
                        </div>
                    </div>


                    <div className="row mt-5">
                        <div className="col-lg-6">
                            <div className="tp-about-3-img p-relative fadeRight">
                                <LazyLoadImage className="About6Image" src={About6Image} alt="" />
                                <LazyLoadImage className="shape-1 About7Image" src={About8Image} alt="" />
                                <div className="shape-2 p-relative">
                                    <LazyLoadImage src={About7Image} className="About8Image" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="tp-fun-fact-title-wrapper support-breadcrumb">
                                <h5>
                                    <span className="tp-section-title__pre">
                                        services <span className="title-pre-color">Digital Marketing</span>
                                        <LazyLoadImage src={Ringundrline} alt="" />
                                    </span>
                                </h5>
                                <ul className="mb-4 web-development-text-point mt-4">
                                    <li className="mb-2"><span>
                                        <LazyLoadImage src={IconRightSvg} className="me-2" />
                                    </span>
                                        <b>Search Engine Optimization (SEO): </b>
                                        Boost your website's visibility and organic traffic by optimizing your online presence, targeting relevant keywords, and implementing effective SEO strategies.
                                    </li>
                                    <li className="mb-2"><span>
                                        <LazyLoadImage src={IconRightSvg} className="me-2" />
                                    </span>
                                        <b>Pay-Per-Click Advertising (PPC): </b>
                                        Drive targeted traffic to your website with strategic PPC campaigns, leveraging platforms like Google Ads, social media advertising, and display advertising.
                                    </li>
                                    <li className="mb-2"><span>
                                        <LazyLoadImage src={IconRightSvg} className="me-2" />
                                    </span>
                                        <b>Social Media Management: </b>
                                        Engage and expand your audience across various social media platforms through strategic content creation, community management, and influencer collaborations.
                                    </li>
                                    <li className="mb-2"><span>
                                        <LazyLoadImage src={IconRightSvg} className="me-2" />
                                    </span>
                                        <b>Content Marketing: </b>
                                        Establish your brand as an industry leader with compelling content that educates, entertains, and resonates with your target audience. We specialize in creating engaging blog posts, articles, videos, and infographics.
                                    </li>
                                </ul>
                                <div className="tp-support-breadcrumb-btn mb-30">
                                    <Link className="tp-btn" to={"/contact"}>How Can We Help</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default ServiceITSolutionsComponent