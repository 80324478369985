import React from "react";
import ContactFormCard from "../ContactFormCard";
import ChateSvg from '../../assets/images/svg/icon-chat.svg'
import PhoneCallSvg from '../../assets/images/svg/icon-phone.svg'
import { LazyLoadImage } from "react-lazy-load-image-component";



const ContactUsCardComponenet = () => {




	return (
		<>
			<section className="tp-contact-area">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="tp-contact-wrapper">
								<div className="tp-contact-title-wrapper">
									<h3 className="tp-contact-title">Contact <span className="title-color">With us</span></h3>
									<p>Email, call, or complete the form to get in touch with us.
										{/* <br /> solve your cloud management and governance cha. */}
									</p>
								</div>
								<div className="tp-contact-content">
									<div className="tp-contact-content-mail d-flex align-items-center">
										<div className="tp-contact-content-mail-icon">
											<span>
												<LazyLoadImage src={ChateSvg} alt="chatsvg" />
											</span>
										</div>
										<h3 className="tp-contact-item-title"><a href="mailto:crisfooddigiverse@gmail.com" target="_blank">crisfooddigiverse@gmail.com</a></h3>
									</div>
									<div className="tp-contact-content-phone d-flex align-items-center">
										<div className="tp-contact-content-phone-icon">
											<span>
												<LazyLoadImage src={PhoneCallSvg} alt="" />
											</span>
										</div>
										<h3 className="tp-contact-item-title"><span><a href="tel:7977989284">+91 7977989284 </a>, </span>
											<span className="tp-contact-item-title-mobile-screen-block-view" ><a href="tel:9175573592">+91 9175573592</a></span>
										</h3>
									</div>
									<div className="tp-contact-location-wrapper d-flex">
										<div className="tp-contact-location">
											<h3 className="tp-contact-location-title">Office Location <i className="fa-regular fa-arrow-down"></i></h3>
											<p>
												Crisfood DigiVerse Office, Near Asma Masjid, Chuniwala Mohalla, New Gauri Pada, Bhiwandi, Maharashtra 421302</p>
										</div>
										{/* <div className="tp-contact-location">
                        <h3 className="tp-contact-location-title">Office Location <i className="fa-regular fa-arrow-down"></i></h3>
                        <p>4517 Washington Ave. Manch
                          ester, Kentucky 39495</p>
                      </div> */}
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-6">
							<ContactFormCard />
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
export default ContactUsCardComponenet