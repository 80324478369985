import React, { useEffect, useState } from 'react';
// import imageBg from '../../assets/images/breadcrumb-bg-1.jpg';
import FlotingImage1 from '../../assets/images/home/floting-image/crisfood_digiverse_android.png'
import FlotingImage2 from '../../assets/images/home/floting-image/crisfood_digiverse_flutter.png';
import FlotingImage3 from '../../assets/images/home/floting-image/crisfood_digiverse_react.png';
import FlotingImage4 from '../../assets/images/home/floting-image/crisfood_digiverse_figma.png';
import FlotingImage5 from '../../assets/images/home/floting-image/crisfood_digiverse_adobe_photoshop.png';
import FlotingImage6 from '../../assets/images/home/floting-image/crisfood_digiverse_wordpress.png';
import FlotingImage7 from '../../assets/images/home/floting-image/crisfood_digiverse_node.png';
import FlotingImage8 from '../../assets/images/home/floting-image/crisfood_digiverse_php.png';
import FlotingImage9 from '../../assets/images/home/floting-image/crisfood_digiverse_adobe_ae.png';
import FlotingImage10 from '../../assets/images/home/floting-image/crisfood_digiverse_adobe_pr.png'
import { LazyLoadImage } from 'react-lazy-load-image-component';










const Slideshow = () => {

    const [currentBanner, setCurrentBanner] = useState(0);
    const banners = [
        require('../../assets/images/home/crisfood_digiverse_home_bg_banner-1.jpg'),
        require('../../assets/images/home/crisfood_digiverse_home_bg_banner-2.jpg'),
        require('../../assets/images/home/crisfood_digiverse_home_bg_banner-3.jpg'),
    ];

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentBanner((prevBanner) => (prevBanner + 1) % banners.length);
        }, 5000);

        return () => {
            clearInterval(timer);
        };
    }, [banners.length]);
    return (
        <>
            <div className="banner-image">
                {/* <img src={imageBg} alt="" /> */}
                <LazyLoadImage src={banners[currentBanner]} alt="crisfood_digiverse_home_bg_banner" />
            </div>

            <div className="contaienr after-banner-image-section">
                {/* <div className="image-text">
                    <h2>
                        Build your digital Empire with us
                    </h2>
                    <p>We help you to magnify your business with beautiful designs, exiquisite technology and engaging social media strategy.</p>
                    <div className="tp-contact-btn text-end">
                        <button type="submit" className="tp-btn">How Can We Help</button>
                    </div>
                </div> */}
                <div className="banner-floting-image">
                    <LazyLoadImage src={FlotingImage1} className="shape-1" alt="crisfood_digiverse_android.png" />
                    <LazyLoadImage src={FlotingImage2} className="shape-2" alt="crisfood_digiverse_flutter.png" />
                    <LazyLoadImage src={FlotingImage3} className="shape-3" alt="crisfood_digiverse_react.png" />
                    <LazyLoadImage src={FlotingImage4} className="shape-4" alt="crisfood_digiverse_figma.png" />
                    <LazyLoadImage src={FlotingImage5} className="shape-5" alt="crisfood_digiverse_adobe_photoshop.png" />
                    <LazyLoadImage src={FlotingImage6} className="shape-6" alt="crisfood_digiverse_wordpress.png" />
                    <LazyLoadImage src={FlotingImage7} className="shape-7" alt="crisfood_digiverse_node.png" />
                    <LazyLoadImage src={FlotingImage8} className="shape-8" alt="crisfood_digiverse_php.png" />
                    <LazyLoadImage src={FlotingImage9} className="shape-9" alt="crisfood_digiverse_adobe_ae.png" />
                    <LazyLoadImage src={FlotingImage10} className="shape-10" alt="crisfood_digiverse_adobe_pr.png" />
                </div>
            </div>

        </>
    )
}

export default Slideshow