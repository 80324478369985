import React from "react";
import About1Image from "../../assets/images/png/about-1.png"
import Shap5Image from "../../assets/images/png/shape-5.png"
import Ringundrline from "../../assets/images/svg/ring-inderline.svg";
import Sideline from "../../assets/images/svg/underline.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";


const AboutITSolutionsComponent = () => {

	return (
		<>

			<section className="tp-about-breadcrumb">
				<div className="tp-about-3-shape d-none d-lg-block">
					<LazyLoadImage src={Shap5Image} alt="" />
				</div>
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="tp-about-breadcrumb-img p-relative text-center fadeLeft">
								<LazyLoadImage src={About1Image} alt="" />
							</div>
						</div>
						<div className="col-lg-6">
							<div className="tp-about-3-wrapper">
								<div className="tp-feature-title-wrapper">
									<span className="tp-section-title__pre">
										about <span className="title-pre-color">IT Solutions</span>
										<LazyLoadImage src={Ringundrline} alt="" />
									</span>
									<h3 className="tp-section-title">We design and develop
										<span className="title-color"> web and mobile applications </span> for our clients worldwide.
										<span className="title-left-shape">
											<LazyLoadImage src={Sideline} alt="" />
										</span>
									</h3>
								</div>
								<p className="text">
									Welcome to Crisfood DigiVerse, a leading digital marketing and development agency committed to helping businesses thrive in the digital landscape. With our expertise, creativity, and cutting-edge strategies, we empower our clients to achieve their online goals and surpass their competitors.
									<br className="mt-5" />
									At Crisfood DigiVerse, we understand that in today's rapidly evolving digital world, a strong online presence is essential for business success. That's why we offer a comprehensive suite of services designed to maximize your brand visibility, engage your target audience, and drive tangible results.
									<br className="mt-5" />
									Our team of seasoned professionals comprises highly skilled experts in various disciplines, including digital marketing, web development, design, content creation, SEO, social media management, and more. We pride ourselves on staying ahead of the curve when it comes to industry trends and technologies, ensuring that our clients receive the most innovative and effective solutions for their specific needs.
									{/* <br /> uphold industry and exchange the worldwide trade of merchandise <br /> through land transport. */}
								</p>
								<div className="tp-about-progressbar-inner d-flex flex-wrap pt-20">
									<div className="tp-about-3-progressbar d-flex align-items-center">
										<div className="circular tl-progress">
											<input type="text" className="knob" value="0" data-rel="50" data-linecap="round"
												data-width="96" data-height="96" data-bgcolor="#ECEEF3" data-fgcolor="#05DAC3" data-thickness=".07" data-readonly="true" disabled />
										</div>
										<div className="tp-about-3-progressbar-title">
											<p>Business <br /> Strategy Growth</p>
										</div>
									</div>
									<div className="tp-about-3-progressbar d-flex align-items-center">
										<div className="circular tl-progress">
											<input type="text" className="knob" value="0" data-rel="75" data-linecap="round"
												data-width="96" data-height="96" data-bgcolor="#ECEEF3" data-fgcolor="#05DAC3" data-thickness=".07" data-readonly="true" disabled />
										</div>
										<div className="tp-about-3-progressbar-title">
											<p>Health Valuable <br /> Ideas</p>
										</div>
									</div>
								</div>
								{/* <div className="tp-about-3-btn-inner d-flex flex-wrap">
									<div className="tp-about-btn ">
										<a className="tp-btn" href="about-us.html">Our History <i className="fa-regular fa-arrow-right-long"></i>
										</a>
									</div>
									<div className="tp-about-3-year">
										<p>
											BRENDON GARREY
											<br />
											<span>
												mayor, since 21st Oct , 2019
											</span>
										</p>
									</div>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</section>


		</>
	)
}
export default AboutITSolutionsComponent