import React from "react";
import { Container } from "react-bootstrap";
import IconRightSvg from "../../assets/images/svg/icon-right.svg";
import ContactSvgIcon from "../../assets/images/svg/contact-icon.svg"
import ChatSvgIcon from "../../assets/images/svg/chat-icon.svg"
import WatchSvgIcon from "../../assets/images/svg/watch-icon.svg"

import FaceBookLogo from "../../assets/images/svg/social/facebook.svg"
import InstagramLogo from "../../assets/images/svg/social/instagram.svg"
import LinkdinLogo from "../../assets/images/svg/social/linkedin.svg"
import WhatsappLogo from "../../assets/images/svg/social/whatsapp.svg"
import { LazyLoadImage } from "react-lazy-load-image-component";







const WhyChoosUsComponents = () => {


    return (
        <>
            <Container>
                <div className="row">
                    <div className="col-lg-8">
                        <div className="tp-portfolio-details-wrapper">
                            <h3 className="tp-portfolio-details-title">Why should you work with us?</h3>
                            <p>By choosing to work with Crisfood DigiVerse, you gain a dedicated partner that is committed to your success.
                                We combine expertise, innovation, and a customer-centric approach to deliver customized digital marketing and development solutions
                                that help your business thrive in the online landscape. Let us be your trusted ally in achieving your digital goals.</p>
                            <div className="tp-portfolio-details-list mb-30">
                                <h6 className="tp-portfolio-details-list-title">There are several compelling reasons why you should choose to work with Crisfood DigiVerse:</h6>
                                <ul>
                                    <li><span>
                                        <LazyLoadImage src={IconRightSvg} className="me-2" />
                                    </span>Expertise and Experience: Our team consists of highly skilled professionals with extensive experience in digital marketing and development. We stay up-to-date with the latest industry trends, best practices, and emerging technologies, allowing us to deliver innovative solutions that drive real results for our clients.
                                    </li>
                                    <li><span>
                                        <LazyLoadImage src={IconRightSvg} className="me-2" />
                                    </span>Customized Approach: We understand that every business is unique, with its own goals, challenges, and target audience. We take the time to understand your specific needs and tailor our strategies and solutions accordingly. Our customized approach ensures that you receive a personalized and effective digital marketing and development strategy.
                                    </li>
                                    <li>
                                        <span>
                                            <LazyLoadImage src={IconRightSvg} className="me-2" />
                                        </span>Results-Driven Focus: Our primary objective is to help your business achieve tangible results and ROI. Whether it's increasing website traffic, generating leads, improving conversion rates, or enhancing brand visibility, we are dedicated to delivering measurable outcomes that contribute to your business growth.
                                    </li>
                                    <li>
                                        <span>
                                            <LazyLoadImage src={IconRightSvg} className="me-2" />
                                        </span>Holistic Solutions: We offer a comprehensive range of digital marketing and development services under one roof. From web design and development to search engine optimization, content creation, social media management, and more, we provide end-to-end solutions that cover all aspects of your online presence. This integrated approach ensures consistency, efficiency, and synergy across your digital marketing efforts.
                                    </li>
                                    <li>
                                        <span>
                                            <LazyLoadImage src={IconRightSvg} className="me-2" />
                                        </span>Innovative Strategies: We thrive on innovation and creativity. Our team constantly explores new strategies and techniques to keep your business ahead of the curve. Whether it's leveraging emerging digital channels, adopting the latest technologies, or incorporating fresh ideas into your campaigns, we are committed to staying at the forefront of the industry to deliver cutting-edge solutions.
                                    </li>
                                    <li>
                                        <span>
                                            <LazyLoadImage src={IconRightSvg} className="me-2" />
                                        </span>Transparent Communication: We believe in open and transparent communication with our clients. We keep you informed at every step of the process, providing regular updates, progress reports, and insights into your campaigns' performance. You can trust us to be accessible, responsive, and proactive in addressing your questions and concerns.
                                    </li>
                                    <li>
                                        <span>
                                            <LazyLoadImage src={IconRightSvg} className="me-2" />
                                        </span>Client-Centric Approach: Your satisfaction is our top priority. We take a client-centric approach, focusing on building long-term partnerships and delivering exceptional customer service. We strive to exceed your expectations, and we value your input and feedback throughout the collaboration.
                                    </li>
                                    <li>
                                        <span>
                                            <LazyLoadImage src={IconRightSvg} className="me-2" />
                                        </span>Proven Track Record: Our portfolio showcases a diverse range of successful projects and satisfied clients. We have a track record of delivering exceptional results for businesses across various industries and sizes. You can rely on our expertise and reputation to propel your business forward.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="tp-portfolio-details-info-box">
                            <div className="tp-portfolio-details-info-address">
                                <h4 className="tp-portfolio-details-info-title"><span>
                                    <LazyLoadImage src={ContactSvgIcon} alt="" />
                                </span>our address</h4>
                                <p> Asma Masjid, Crisfood Office, Chuniwala Mohalla, New Gauri Pada, Bhiwandi, Maharashtra 421302</p>
                            </div>
                            <div className="tp-portfolio-details-info-mail">
                                <h4 className="tp-portfolio-details-info-title"><span>
                                    <LazyLoadImage src={ContactSvgIcon} alt="" />
                                </span>Contact way</h4>
                                <div className="tp-portfolio-details-info-mail-content d-flex">
                                    <span>
                                        <LazyLoadImage src={ChatSvgIcon} alt="" />

                                    </span>
                                    <div className="tp-portfolio-details-info-mail-text">
                                        <p className="tp-portfolio-details-info-mail-text-para">
                                            <a href="mailto:info@crisfooddigiverse.com" target="_blank">info@crisfooddigiverse.com</a></p>
                                        <p className="tp-portfolio-details-info-mail-text-para"><a href="tel:9158445043">+91 (915) 8445043</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="tp-portfolio-details-info-open d-flex">
                                <div className="tp-portfolio-details-info-open-icon">
                                    <span>
                                        <LazyLoadImage src={WatchSvgIcon} alt="" />
                                    </span>
                                </div>
                                <div className="tp-portfolio-details-info-open-content">
                                    <h4 className="tp-portfolio-details-info-title">Opening Houres</h4>
                                    <p>Mon - Sat(10.00am - 7.00pm)</p>
                                    <p>Sunday - Closed</p>
                                </div>
                            </div>
                            <div className="tp-portfolio-details-info-social">
                                <a href="https://www.facebook.com/profile.php?id=100086754152589&mibextid=ZbWKwL" target="_blank">
                                    <LazyLoadImage src={FaceBookLogo} alt="" />
                                </a>
                                <a href="https://instagram.com/crisfood_digiverse?igshid=MzRlODBiNWFlZA==" target="_blank">
                                    <LazyLoadImage src={InstagramLogo} alt="" />
                                </a>
                                <a href="https://www.linkedin.com/in/crisfood-digiverse-3495aa282" target="_blank">
                                    <LazyLoadImage src={LinkdinLogo} alt="" />
                                </a>
                                <a href="https://wa.me/9158445043" target="_blank">
                                    <LazyLoadImage src={WhatsappLogo} alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>

        </>
    )
}

export default WhyChoosUsComponents