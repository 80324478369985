import React from "react";
import FooterCardComponent from "../../components/FooterCardComponent";



const FooterPage = () => {



    return (
        <>
            <FooterCardComponent />
        </>
    )
}

export default FooterPage
