import axios from "axios";
import React, { useState } from "react";
import Swal from 'sweetalert2';


const ContactFormCard = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [mail, setMail] = useState('');
    const [mess, setMess] = useState('');
    const [serviceType, setServiceType] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const [Validation, setValidation] = useState(null);



    // const validateMobileNumber = number => {
    //     const pattern = /^[0-9]{10}$/;
    //     return pattern.test(number);
    // };

    // const validateEmail = email => {
    //     const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //     return pattern.test(email);
    // };

    const handleSubmit = () => {
        setIsLoading(true);
        if (name.trim() === '' || mail.trim() === '' || phone.trim() === '' || mess.trim() === '') {
            Swal.fire('Error', 'Please fill in all fields', 'error');
            setIsLoading(false);
            setValidation({
                name: !name.trim(),
                mail: !mail.trim(),
                phone: !phone.trim(),
                mess: !mess.trim(),
            })
            return;

        }
        // else if (!validateMobileNumber(phone)) {
        //     Swal.fire('Error', 'Please enter a valid mobile number', 'error');
        //     return;
        // } else if (!validateEmail(mail)) {
        //     Swal.fire('Error', 'Please enter a valid email address', 'error');
        //     return;
        // }

        const emailBody = `<html>
          <body>
            <h3>Contact Request Details:</h3>
            <p><strong>Name: </strong>${name}</p>
            <p><strong>Contact No.: </strong>${phone}</p>
            <p><strong>Email: </strong>${mail}</p>
            <p><strong>Message: </strong>${mess}</p>
          </body>
        </html>`;

        const sentFrom = mail; // Replace with your email address
        const sentName = name;
        const subject = 'Contact request information from digiverse website';
        const dataString =
            'emailBody=' +
            encodeURIComponent(emailBody) +
            '&sentFrom=' +
            encodeURIComponent(sentFrom) +
            '&sentName=' +
            encodeURIComponent(sentName) +
            '&subject=' +
            encodeURIComponent(subject) +
            '&sendmail=';

        axios
            .post('https://www.crisfooddigiverse.com/phpMailer/phpmailsend.php', dataString, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            .then(response => {
                console.log('email response', response.data);
                setIsLoading(false);
                if (response.data === 'success') {
                    Swal.fire('Success', 'Details sent', 'success');
                    setName('');
                    setMail('');
                    setPhone('');
                    setMess('');
                    setValidation();
                } else {
                    Swal.fire('Error', 'Please enter valid email address', 'error');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                setIsLoading(false);
                Swal.fire('Error', 'An error occurred while sending the email', 'error');
            });

        console.log('correct');
        // return true;
    };

    return (
        <>
            <div className="tp-contact-form">
                <h3 className="tp-contact-form-title">Contact Us</h3>
                <p>Your email address will not be published. Required fields <br /> are marked *</p>
                <div className="row tp-gx-10">
                    <div className="col-md-6">
                        <div className="tp-contact-input">
                            <input
                                type="text"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                placeholder="Your Name*"
                                className={Validation?.name && "validation-border"}
                            />
                            {Validation?.name &&
                                <div className='validationText'>Please provide a valid Name</div>
                            }
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="tp-contact-input">
                            <input
                                type="email"
                                value={mail}
                                onChange={e => setMail(e.target.value)}
                                placeholder="Email Address*"
                                className={Validation?.mail && "validation-border"}
                            />
                            {Validation?.mail &&
                                <div className='validationText'>Please provide a valid Email</div>
                            }
                        </div>
                    </div>
                    {/* <div className="col-md-6">
                        <div className="tp-contact-input">
                            <input
                                type="text"
                                value={serviceType}
                                onChange={e => setServiceType(e.target.value)}
                                placeholder="Service Type"
                            />
                        </div>
                    </div> */}
                    <div className="col-md-12">
                        <div className="tp-contact-input">
                            <input
                                type="text"
                                value={phone}
                                onChange={e => setPhone(e.target.value.replace(/[^0-9]/g, ''))}
                                placeholder="Phone Number*"
                                className={Validation?.phone && "validation-border"}
                                maxLength={15}
                            />
                            {Validation?.phone &&
                                <div className='validationText'>Please provide a valid phone number</div>
                            }
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="tp-contact-input">
                            <textarea
                                value={mess}
                                onChange={e => setMess(e.target.value)}
                                placeholder="Enter Your Message here*"
                                className={Validation?.mess && "validation-border"}
                            ></textarea>
                            {Validation?.phone &&
                                <div className='validationText'>Please provide a valid Message</div>
                            }
                        </div>
                    </div>
                    <div className="tp-contact-btn mt-10">
                        <button onClick={handleSubmit} className="tp-btn loader-button">
                            {isLoading ? (
                                <>
                                    <span>Sending Message....</span>
                                    <div className="loader"></div>
                                </>
                            ) : (
                                'Send Message'
                            )}
                        </button>
                    </div>

                </div>
                {/* <p className="ajax-response"></p> */}
            </div>
        </>
    )
}
export default ContactFormCard