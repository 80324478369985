import React from "react";
import CommonBannerImage from "../../components/CommonBannerImage";
import FooterPage from "../FooterPage";
import { Container } from "react-bootstrap";
import PortfolioCatAreaCard from "../../components/PortfolioCatAreaCard";
import ContactUsCardComponenet from "../../components/ContactUsCardComponenet";
import GoogleIframMapCard from "../../components/GoogleIframMapCard";


const HomePage = () => {



  return (
    <>
      <CommonBannerImage />
      <Container className="mb-5">
        <ContactUsCardComponenet />
      </Container>
      <div className="google-map-main-container">
        <div className="cat-area-card-google-map" >
          <PortfolioCatAreaCard />
        </div>
        <GoogleIframMapCard />
      </div>
      <FooterPage />
    </>
  )
}
export default HomePage