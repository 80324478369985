import React from "react";
// import BrandLogoBackground from '../../assets/images/brand-logo/shape-bg.png';
import CrisFoodLogo from '../../assets/images/brand-logo/crisfood_digiverse_crisfood.png';
import UnityConstructionLogo from '../../assets/images/brand-logo/crisfood_digiverse_unity_construction.png';
import GazeeboLogo from '../../assets/images/brand-logo/crisfood_digiverse_gazeebo.png';
import SilverforkMadinaLogo from '../../assets/images/brand-logo/crisfood_digiverse_silverfork_madina.png';
import FireWingsLogo from '../../assets/images/brand-logo/crisfood_digiverse_fire_wings.png';
import AarisAutomationLogo from '../../assets/images/brand-logo/crisfood_digiverse_silverfork_ariss.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { LazyLoadImage } from "react-lazy-load-image-component";




const BrandSLogoComponent = () => {


    return (
        <>
            <div className="dg-clinet-brand-area">
                {/* <div className="dg-clinet-brand-bg" data-background={BrandLogoBackground}></div> */}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-4">
                            <div className="dg-clinet-brand-item">
                                <LazyLoadImage className="shape-1" src={CrisFoodLogo} alt="" />
                                <LazyLoadImage className="shape-2" src={AarisAutomationLogo} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-4 order-sm-3 order-md-2">
                            <div className="dg-clinet-brand-item">
                                <LazyLoadImage className="shape-3" src={GazeeboLogo} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-4 order-sm-2 order-md-3">
                            <div className="dg-clinet-brand-item">
                                <LazyLoadImage className="shape-4" src={SilverforkMadinaLogo} alt="" />
                                <LazyLoadImage className="shape-5" src={FireWingsLogo} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-4 order-sm-2 order-md-3">
                            <div className="dg-clinet-brand-item">
                                <LazyLoadImage className="shape-5" src={UnityConstructionLogo} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center pt-4 pb-5">
                        <div className="col-lg-8">
                            <div className="dg-clinet-brand-trend text-center">
                                <p>
                                    <FontAwesomeIcon className="dg-clinet-brand-right" icon={faArrowRight} />
                                    IMPROVE AND INNOVATE WITH THE <a href="#">Digiverse</a>
                                    <FontAwesomeIcon className="dg-clinet-brand-left" icon={faArrowLeft} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BrandSLogoComponent