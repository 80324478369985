import React from "react";



const GoogleIframMapCard = () => {


	return (
		<>
			<div className="home-page-google-map">
				<iframe
					title="Map"
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3765.731530567368!2d73.04801237508191!3d19.294037781955936!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bd5deece825b%3A0xb42af685b6b578e7!2sCrisfood!5e0!3m2!1sen!2sin!4v1683367485934!5m2!1sen!2sin"
					width="100%"
					height="100%"
					allowFullScreen={false}
					referrerPolicy="no-referrer-when-downgrade"
				></iframe>
			</div>
		</>
	)
}
export default GoogleIframMapCard