import React from "react";
import NotFoundComponent from "../../components/NotFoundComponent";


const NotFoundPage = () => {



    return (
        <>
            <NotFoundComponent />
        </>
    )


}


export default NotFoundPage