import React from "react";
import LogoImage from "../../assets/images/png/img-shape.png"
import Image from "../../assets/images/img-1.jpg"
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Card, Col, Row } from "react-bootstrap";




const PartnerCard = ({ productData }) => {


	return (
		<>
			<Row className="partner-main-card">
				{
					productData.map((item, index) => {
						return (
							<Col xl={3} lg={4} md={6} sm={6} className="gy-3" key={index}>
								<Card className="main-card user-select-none">
									<Card.Body className="border-bottom">
										<div className="row d-flex align-items-center flex-column">
											<a href={Image}>
												<div className="profile-image">
													<LazyLoadImage
														src={item.image}
														alt="crisfood_digiverse_partner_card_image" />
												</div>
											</a>
											<h4 className="col mt-4 partner-card-title">
												{item.title}
											</h4>
											<div className="col mt-2 partner-card-description">
												{item.name}
											</div>
										</div>
									</Card.Body>
								</Card>
							</Col>
						)
					})
				}
			</Row>
		</>
	)
};

export default PartnerCard