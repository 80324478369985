import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TitleSvg from "../../assets/images/svg/titile.svg"
import { LazyLoadImage } from "react-lazy-load-image-component";



const CommonBannerImage = () => {
    {/*------------------------------- useLocation Get Current Location --------------------------*/ }
    const location = useLocation();
    const [pathname, setPathname] = useState('')
    // console.log("currrent location", location.pathname);

    {/*------------------------------- useEffect Component --------------------------*/ }
    useEffect(() => {
        setPathname(location.pathname)
    }, [])



    return (
        <>
            <div className="breadcrumb__area breadcrumb-style">
                <div className="breadcrumb__bg-overlay m-img"></div>
                <div className="container breadcrumb__bg-overlay_second">
                    <div className="row justify-content-center breadcrumb-height">
                        <div className="col-xl-10">
                            <div className="breadcrumb__content text-center">
                                {
                                    pathname === "/our-services" &&
                                    <h3 className="breadcrumb__title">Our Services
                                        <LazyLoadImage src={TitleSvg} alt="" />
                                    </h3>
                                }
                                {
                                    pathname === "/portfolio" &&
                                    <h3 className="breadcrumb__title">Portfolio
                                        <LazyLoadImage src={TitleSvg} alt="" />
                                    </h3>
                                }
                                {
                                    pathname === "/contact" &&
                                    <h3 className="breadcrumb__title">Contact Us
                                        <LazyLoadImage src={TitleSvg} alt="" />
                                    </h3>
                                }
                                {
                                    pathname === "/about" &&
                                    <h3 className="breadcrumb__title">About Us
                                        <LazyLoadImage src={TitleSvg} alt="" />
                                    </h3>
                                }
                                {/* <div className="breadcrumb__list breadcrumb__list-translate">
                                    <span><Link To={"/"}>Home</Link></span>
                                    <span className="dvdr">
                                        <FontAwesomeIcon icon={faAngleRight} />
                                    </span>
                                    <span>pages</span>
                                    <span className="dvdr">
                                        <FontAwesomeIcon icon={faAngleRight} />
                                    </span>
                                    <span>service</span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default CommonBannerImage