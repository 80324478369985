import React from "react";
import { Link } from "react-router-dom";




const WorkTogetherCard = () => {


    return (
        <>
            <div className="work-together-card">
                <h3 className="badge badge-font">Let's Work Together</h3>
                <h2 className="card-title">Need a successful project?</h2>
                <p className="card-text">We're ready to collaborate on your next project.</p>
                <div className="tp-contact-btn mt-10">
                    <Link to={'/contact'}>
                        <button type="submit" className="tp-btn">Estimate Project</button>
                    </Link>
                </div>
                <div className="call-us-now">
                    <span className="phone-number"><h3 className="tp-contact-item-title"><span className="call-text">Call Us Now:</span><a className="estimate-tp-number-text" href="tel:+919158445043">+91 9158445043 </a></h3></span>
                </div>
            </div>
        </>
    )
}
export default WorkTogetherCard