import React, { useState } from "react";
import DigiVerseLogo from "../../assets/images/footer-logo.png"
import SendSVg from "../../assets/images/svg/sensvg-footer.svg"
import FaceBookLogo from "../../assets/images/svg/social/facebook.svg"
import InstagramLogo from "../../assets/images/svg/social/instagram.svg"
import PinterestLogo from "../../assets/images/svg/social/pinterest.svg"
import WhatsappLogo from "../../assets/images/svg/social/whatsapp.svg"
import LinkdinLogo from "../../assets/images/svg/social/linkedin.svg"
import ThreadLogo from "../../assets/images/png/thread.png"
import YouTubeLogo from "../../assets/images/svg/social/youtube.svg"




import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocation, faMessage, faPhone } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";




const FooterCardComponent = () => {
	const [mail, setMail] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const [buttonHideShow, setbuttonHideShow] = useState(false);



	const handleSubmit = () => {
		setIsLoading(true);
		if (mail.trim() === '') {
			Swal.fire('Error', 'Please fill in empty fields', 'error');
			setIsLoading(false);
			return;

		}

		const emailBody = `<html>
          <body>
            <h3>Newsletter Request:</h3>
            <p><strong>Email: </strong>${mail}</p>
          </body>
        </html>`;

		const sentFrom = mail; // Replace with your email address
		const subject = 'Newsletter request information from digiverse website';
		const dataString =
			'emailBody=' +
			encodeURIComponent(emailBody) +
			'&sentFrom=' +
			encodeURIComponent(sentFrom) +
			'&sentName=' +
			encodeURIComponent(mail) +
			'&subject=' +
			encodeURIComponent(subject) +
			'&sendmail=';

		axios
			.post('https://www.crisfooddigiverse.com/phpMailer/phpmailsend.php', dataString, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			})
			.then(response => {
				console.log('email response', response.data);
				setIsLoading(false);
				if (response.data === 'success') {
					Swal.fire('Success', 'Email sent', 'success');
					setMail('');
				} else {
					Swal.fire('Error', 'Please enter valid email address', 'error');
				}
			})
			.catch(error => {
				console.error('Error:', error);
				setIsLoading(false);
				Swal.fire('Error', 'Please enter valid email address', 'error');
			});

		console.log('correct');
		// return true;
	};
	const handleQuantityChange = (event) => {
		const value = event.target.value;
		setMail(value);
		// setEmailError(""); // Clear previous error message

		// Email validation using a simple regular expression
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (emailPattern.test(value)) {
			setbuttonHideShow(true)
		}
	};


	return (
		<>
			<footer className="tp-footer-3-area tp-footer-bg ">
				<div className="container">
					<div className="tp-footer-3-main-area">
						<div className="row">
							<div className="col-lg-4 col-md-6">
								<div className="tp-footer-widget tp-footer-3-col-1">
									<div className="tp-footer-logo">
										<a href="#"> <LazyLoadImage src={DigiVerseLogo} alt="" /></a>
									</div>
									<div className="tp-footer-widget-content">
										<div className="tp-footer-info">
											{/* <p className="p">The world’s first and largest digital marketplace <br /> for crypto collectibles and non-fungible tokens (NFTs). Buy</p> */}
											<div className="tp-footer-main-location">
												<a href="https://www.google.com/maps/dir/19.2928349,73.0573721/Crisfood+Office,+Chuniwala+Mohalla,+Asma+Masjid,+New+Gauri+Pada,+Bhiwandi,+Maharashtra+421302/@19.294974,73.0496439,16z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3be7bd5deece825b:0xb42af685b6b578e7!2m2!1d73.0505873!2d19.2940378?entry=ttu" target="_blank">
													<FontAwesomeIcon icon={faLocation} className="me-2" />

													Crisfood DigiVerse Office, Near Asma Masjid, <br /> Chuniwala Mohalla, New Gauri Pada, Bhiwandi, Maharashtra 421302
												</a>
											</div>
											<div className="tp-footer-main-mail">
												<a href="mailto:crisfooddigiverse@gmail.com" target="_blank"><FontAwesomeIcon icon={faMessage} className="me-2" />crisfooddigiverse@gmail.com</a>
											</div>
											<div className="tp-footer-main-mail">
												<a href="tel:+919158445043">
													<FontAwesomeIcon icon={faPhone} className="me-2" />
													+91 9158445043</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6">
								<div className="tp-footer-widget tp-footer-3-col-2">
									<h3 className="tp-footer-widget-title">Services</h3>
									<div className="tp-footer-widget-content">
										<ul>
											<li><a href="#">Digital Marketing & Advertising</a></li>
											<li><a href="#">Mobile App Development</a></li>
											<li><a href="#">Web Development</a></li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-lg-4 col-md-6">
								<div className="tp-footer-widget tp-footer-3-col-3">
									<h3 className="tp-footer-widget-title">Newsletter</h3>
									<div className="tp-footer-from">
										<div className="tp-footer-text-email p-relative">
											<div>
												<input
													type="email"
													value={mail}
													// onChange={e => setMail(e.target.value)}
													onChange={handleQuantityChange}
													placeholder="Enter Email Address*"
												/>
												{buttonHideShow === true ? <span onClick={handleSubmit} >
													{isLoading ? (
														<div className="loader newsletter-loader "></div>
													) : (
														<LazyLoadImage className="newsletter-svg" src={SendSVg} />
													)}

												</span>
													: undefined}
											</div>
										</div>
										{/* <div className="tp-footer-form-check">
											<input className="form-check-input" id="flexCheckChecked" type="checkbox" />
											<label className="form-check-label" htmlFor="flexCheckChecked">
												I agree to all your terms and policies
											</label>
										</div> */}
										<div className="tp-footer-widget-social">
											<a href="https://wa.me/9158445043" target="_blank">
												<LazyLoadImage src={WhatsappLogo} alt="" height={20} />
											</a>
											<a href="https://instagram.com/crisfood_digiverse?igshid=MzRlODBiNWFlZA==" target="_blank">
												<LazyLoadImage src={InstagramLogo} alt="" height={20} />

											</a>
											<a href="https://www.facebook.com/profile.php?id=100086754152589&mibextid=ZbWKwL" target="_blank">
												<LazyLoadImage src={FaceBookLogo} alt="" height={20} />
											</a>
											<a href="https://www.linkedin.com/in/crisfood-digiverse-3495aa282" target="_blank">
												<LazyLoadImage src={LinkdinLogo} alt="" height={20} />
											</a>
											<a href="https://www.threads.net/t/Cub2TdJsmax/?igshid=NTc4MTIwNjQ2YQ%3D%3D" target="_blank">
												<LazyLoadImage src={ThreadLogo} alt="" height={20} />
											</a>
											<a href="https://youtube.com/@CrisfoodDigiverse" target="_blank">
												<LazyLoadImage src={YouTubeLogo} alt="" height={20} />
											</a>
											<a href="https://www.pinterest.com/cdigiverse/" target="_blank">
												<LazyLoadImage src={PinterestLogo} alt="" height={20} />
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="tp-footer-copyright-area p-relative">
						<div className="row">
							<div className="col-md-12 col-lg-6">
								<div className="tp-footer-copyright-inner">
									<p> ©Copyright <span>©2023</span> Digiverse All Rights Reserved</p>
								</div>
							</div>
							<div className="col-md-12 col-lg-6">
								<div className="tp-footer-copyright-inner text-lg-end">
									<a href="#" className="me-5">Terms and conditions</a>
									<a className="ml-50" href="#"> Privacy policy</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}

export default FooterCardComponent
