import React from "react";
import OperationIcon from '../../assets/images/svg/operation-svgrepo.svg';
import ProjectsIcon from '../../assets/images/svg/projects-svgrepo.svg'
import SpecialistIcon from '../../assets/images/svg/technical-svgrepo.svg'
import { LazyLoadImage } from "react-lazy-load-image-component";


const OurServicesCard = () => {


    return (
        <>

            <div className="service-cards">
                <div className="service-card">
                    <LazyLoadImage src={OperationIcon} alt="Years of Operation" className="service-icon" />
                    <p className="service-title">3+ Years of Operation</p>
                </div>
                <div className="service-card">
                    <LazyLoadImage src={ProjectsIcon} alt="Projects Delivered" className="service-icon" />
                    <p className="service-title">20+ Projects Delivered</p>
                </div>
                <div className="service-card">
                    <LazyLoadImage src={SpecialistIcon} alt="Specialists" className="service-icon" />
                    <p className="service-title">15+ Specialists</p>
                </div>
            </div>
            {/* <div className="tp-contact-btn mt-3	 text-center">
                    <Link to={"/our-services"} >
                        <button type="submit" className="tp-btn">Services</button>
                    </Link>
                </div> */}
        </>
    )
}

export default OurServicesCard