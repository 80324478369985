import React from 'react';
import NoImageFound from '../../assets/images/icon-image-not-found.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';



const PortFolioCardComponent = ({ PortFolioData }) => {

	return (
		<>
			<section className="tp-portfolio-area">
				<div className="container">
					<div className="row">
						{
							PortFolioData.map((value, index) => {

								return (
									<div key={index} className="col-xl-4 col-lg-6 col-md-6">
										<div className="tp-portfolio-item-wrapper">
											<div className="tp-portfolio-item-thumb">
												<LazyLoadImage src={value.image ? value.image : NoImageFound} alt="crisfood_digiverse_company_card_image" />
											</div>
											<div className="tp-portfolio-item-content">
												<span className="tp-portfolio-item-subtitle">{value.name}</span>
												<h3 className="tp-portfolio-item-title"><a href="#">{value.secondName}</a></h3>
												<div className="tp-portfolio-item-content-btn">
													<a href={value.link} target='_blank'>{value.projects}
														<FontAwesomeIcon icon={faArrowRight} className='ms-2' /></a>
												</div>
											</div>
										</div>
									</div>
								)
							})
						}
					</div>
				</div>
			</section>
		</>
	);
};

export default PortFolioCardComponent;
