import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import CommonBannerImage from '../../components/CommonBannerImage';
import PortFolioCardComponent from '../../components/PortFolioCardComponent';
import FooterPage from '../FooterPage';


const PortfolioPage = () => {
	const [PortFolioData, setPortFolioData] = useState([
		{
			name: 'Food Delivery App',
			secondName: 'Crisfood',
			image: require('../../assets/images/portfoilo/company/crisfood_digiverse_crisfood.jpg'),
			projects: "View Projects",
			link: "https://crisfood.com/",
		},
		{
			name: 'Restaurant Website',
			secondName: 'FireWings',
			image: require('../../assets/images/portfoilo/company/crisfood_digiverse_fire_wings.jpg'),
			projects: "View Projects",
			link: "https://indiafirewings.com/",
		},
		{
			name: 'Wedding Website',
			secondName: 'Humsafar',
			image: require('../../assets/images/portfoilo/company/crisfood_digiverse_humsafar.jpg'),
			projects: "View Projects",
			link: "https://www.humsafarmarriage.com/",
		},
		{
			name: 'Restaurant Website',
			secondName: 'Sahil Hotel',
			image: require('../../assets/images/portfoilo/company/crisfood_digiverse_sahil.jpg'),
			projects: "View Projects",
			link: "https://sahilhotelbhiwandi.com/",
		},
		{
			name: 'Restaurant Website',
			secondName: 'Silver Fork Madina',
			image: require('../../assets/images/portfoilo/company/crisfood_digiverse_silver.jpg'),
			projects: "View Projects",
			link: "https://silverforkmadina.com/home/index.html",
		},
		{
			name: 'Construction Website',
			secondName: 'Unity Construction',
			image: require('../../assets/images/portfoilo/company/crisfood_digiverse_unity_construction.jpg'),
			projects: "View Projects",
			link: "https://unityconstruction.in/",
		},
		{
			name: 'Automation Website',
			secondName: 'Ariss',
			image: require('../../assets/images/portfoilo/company/crisfood_digiverse_ariss.jpg'),
			projects: "View Projects",
			link: "https://www.ariss.io",
		},
	]);


	return (
		<>
			<CommonBannerImage />
			<Container>
				<PortFolioCardComponent PortFolioData={PortFolioData} />
			</Container>

			<FooterPage />

		</>
	);
};

export default PortfolioPage;
